import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getSessionData, removeSessionData } from "../apis/auth.atom";
import { useAppDispatch, useAppSelector } from "../store/stateHooks";
import { UserAuthActions } from "../store/appStateSlices/userAuthSlice";
import Protected, { PrivateRoute } from "./Protected";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import styles from "./WebRoutes.module.css";

// Lazy loaded components
const PageManager = lazy(() => import("../components/pages/PageManager"));
const AdminLogin = lazy(() => import("../components/Admin/Auth/AdminLogin"));
const AdminWrapper = lazy(() => import("../components/Admin/AdminWrapper"));
const Dashboard = lazy(() => import("../components/Admin/Dashboard/Dashboard"));
const SubmittedOffers = lazy(() => import("../components/Admin/Dashboard/SubmittedOffers"));
const ForgotPassword = lazy(() => import("../components/Admin/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../components/Admin/Auth/ResetPassword"));
const AddNewAdmin = lazy(() => import("../components/Admin/Dashboard/AddNewAdmin"));
const ManageAdmin = lazy(() => import("../components/Admin/Dashboard/ManageAdmin"));
const AdminProfile = lazy(() => import("../components/Admin/Dashboard/AdminProfile"));
const ManageUsersQueries = lazy(() => import("../components/Admin/Dashboard/ManageUsersQueries"));
const UserDashboard = lazy(() => import("../components/users/dashboard"));
const Profile = lazy(() => import("../components/users/dashboard/profile"));
const UserResetPassword = lazy(() => import("../components/users/dashboard/resetPassword"));
const NoInternetPage = lazy(() => import("../components/UI/noInternet"));
const PageNotFound = lazy(() => import("../components/UI/pageNotFound"));
const OtpVerify = lazy(() => import("../components/Admin/Auth/otpVerify"));
// const OfferDetails = lazy(() => import("../components/users/dashboard/offerDetails"));
const OfferPage = lazy(() => import("../components/users/dashboard/offerPage"));
const AdminOffers = lazy(() => import("../components/Admin/Dashboard/adminOffer"));
const ContactScreen = lazy(() => import("../components/Admin/Dashboard/contactScreens/contactScreen"));
const Entreprises = lazy(() => import("../components/Admin/Dashboard/entreprisesScreen"));
const ContactUpdate = lazy(() => import("../components/Admin/Dashboard/contactScreens/contactDetails"));
const Devis = lazy(() => import("../components/Admin/Dashboard/Portefeuille/devis"));
const PortefeuilleContrats = lazy(() => import("../components/Admin/Dashboard/Portefeuille/contrats"));
const ContactDetails = lazy(() => import("../components/Admin/Dashboard/Portefeuille/contratDetails"));
const MentionsLegales = lazy(() => import("../components/pages/MentionsLegales"));
const SubscribePage = lazy(() => import("../Subscribe/SubscribePage"));
const ContactUs = lazy(() => import("../components/Admin/Dashboard/contactUs/constactUsList"));
const ContactUsDetails = lazy(() => import("../components/Admin/Dashboard/contactUs/contactUsDetails"));

function WebRoutes() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!getSessionData()) {
      const user = {
        auth: false,
      };
      dispatch(UserAuthActions.setUserAuth(user));
      removeSessionData();
    }
  }, []);

  const { user } = useAppSelector((state) => state.user);
  const isAuthenticated = user && user.auth;
  return (
    <BrowserRouter>
      <Suspense fallback={<div className={styles.spinnerContainer}><LoadingSpinner /></div>}>
        <Routes>
          <Route path="/" element={<PageManager />}></Route>
          <Route exact path="*" element={<PageNotFound />} />
          <Route exact path="/mentions_legales" element={<MentionsLegales />} />
          <Route exact path="/subscribe" element={<SubscribePage />} />
        
          <Route path="/no-internet" element={<NoInternetPage />} />
          <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reset-password" element={<UserResetPassword />} />
            {/* <Route path="/offer-details/:offerId" element={<OfferDetails />} /> */}
            <Route path="/offer-details/:offerId" element={<OfferPage />} />
            
          </Route>
          {/* ==============| Admin Routes |================= */}
          <Route path="/admin-login" exact element={<AdminLogin />}></Route>
          <Route path="/otp-veryfy/:id" exact element={<OtpVerify />}></Route>
          <Route
            path="/forgot-password"
            exact
            element={<ForgotPassword />}
          ></Route>
          <Route
            path="/reset-password/:token"
            exact
            element={<ResetPassword />}
          ></Route>
          <Route
            path="/admin/profile"
            exact
            element={
              <Protected>
                <AdminWrapper component={<AdminProfile />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/dashboard"
            exact
            element={
              <Protected>
                <AdminWrapper component={<Dashboard />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/add-new-admin"
            exact
            element={
              <Protected>
                <AdminWrapper component={<AddNewAdmin />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/manage-admins"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ManageAdmin />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/submitted-offers"
            exact
            element={
              <Protected>
                <AdminWrapper component={<SubmittedOffers />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/mes-offres"
            exact
            element={
              <Protected>
                <AdminWrapper component={<AdminOffers />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/contact"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ContactScreen />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/contact-update"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ContactUpdate />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/entreprise"
            exact
            element={
              <Protected>
                <AdminWrapper component={<Entreprises />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/portefeuille-contrats"
            exact
            element={
              <Protected>
                <AdminWrapper component={<PortefeuilleContrats />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/portefeuille-contacts-details"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ContactDetails />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/contact-us"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ContactUs />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/contact-us-details/:contactId"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ContactUsDetails />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/devis"
            exact
            element={
              <Protected>
                <AdminWrapper component={<Devis />} />
              </Protected>
            }
          ></Route>
          <Route
            path="/admin/users/queries"
            exact
            element={
              <Protected>
                <AdminWrapper component={<ManageUsersQueries />} />
              </Protected>
            }
          ></Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default WebRoutes;
