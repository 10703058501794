import React from 'react';
import { Helmet } from 'react-helmet';
import insuranceAgencySchema from '../../schema/insuranceAgencySchema';
import faqSchema from '../../schema/faqSchema';

const SchemaMarkup = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(insuranceAgencySchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(faqSchema)}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;
