import {
    setSession,
    getSession,
    removeSession,
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage,
    Keys,
} from '../utils/storage';

// Set the session data and persist to local storage.
export const setSessionData = (sessionId) => {
    setSession(Keys.SessionId, sessionId);
};

// Get the chat session data
export const getSessionData = () => getSession(Keys.SessionId);

// Remove session from ses storage.
export const removeSessionData = () => {
    removeSession(Keys.SessionId);
};

// Set the auth data and persist to local storage.
export const setRefrashToken = (data) => {
    setLocalStorage(Keys.SessionId, data);
};
// Get the auth data
export const getRefrashToken = () => getLocalStorage(Keys.SessionId);

// Remove the auth data from local storage.
export const removeRefrashToken = () => {
    removeLocalStorage(Keys.SessionId);
};
