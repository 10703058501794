import { useEffect } from 'react';

// Web Vitals tracking
export const reportWebVitals = ({ id, name, label, value }: any) => {
  // Log to console with formatted output
  // console.log(`%c${label}: ${name}`, 'color: #0066cc; font-weight: bold;', {
  //   id,
  //   value: Math.round(value * 100) / 100,
  //   metric: name,
  // });
};

// Performance Metrics Hook
export const usePerformanceMonitor = (componentName: string) => {
  useEffect(() => {
    // Mark component mount
    performance.mark(`${componentName}-mount-start`);

    // Measure render time
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        console.log(`%c${componentName} Render Time:`, 'color: #00cc66; font-weight: bold;', 
          Math.round(entry.duration * 100) / 100, 'ms');
      });
    });

    observer.observe({ entryTypes: ['measure'] });

    return () => {
      // Mark component unmount
      performance.mark(`${componentName}-mount-end`);
      performance.measure(
        `${componentName}-mount-duration`,
        `${componentName}-mount-start`,
        `${componentName}-mount-end`
      );
      observer.disconnect();
    };
  }, [componentName]);
};

// Network Request Monitor
export const monitorNetworkRequest = async (url: string, options?: RequestInit) => {
  const startTime = performance.now();
  try {
    const response = await fetch(url, options);
    const endTime = performance.now();
    const duration = endTime - startTime;

    console.log(`%cAPI Call to ${url}:`, 'color: #cc6600; font-weight: bold;', {
      duration: Math.round(duration * 100) / 100 + 'ms',
      status: response.status,
      ok: response.ok
    });

    return response;
  } catch (error) {
    const endTime = performance.now();
    console.error(`%cAPI Call Failed ${url}:`, 'color: #cc0000; font-weight: bold;', {
      duration: Math.round((endTime - startTime) * 100) / 100 + 'ms',
      error
    });
    throw error;
  }
};

// Add type declaration for Chrome-specific performance memory
interface ChromePerformanceMemory {
  usedJSHeapSize: number;
  totalJSHeapSize: number;
  jsHeapSizeLimit?: number;
}

declare global {
  interface Performance {
    memory?: ChromePerformanceMemory;
  }
}

// Memory Usage Monitor
export const logMemoryUsage = () => {
  if ('memory' in performance) {
    const memory = performance.memory as ChromePerformanceMemory;
    console.log('%cMemory Usage:', 'color: #9900cc; font-weight: bold;', {
      usedJSHeapSize: `${Math.round(memory.usedJSHeapSize / 1048576)} MB`,
      totalJSHeapSize: `${Math.round(memory.totalJSHeapSize / 1048576)} MB`,
    });
  }
};

// Resource Loading Monitor
export const monitorResourceLoading = () => {
  const observer = new PerformanceObserver((list) => {
    list.getEntries().forEach((entry) => {
      if (entry.entryType === 'resource') {
        const resource = entry as PerformanceResourceTiming;
        console.log(`%cResource Loaded: ${resource.name}`, 'color: #666699; font-weight: bold;', {
          duration: Math.round(resource.duration * 100) / 100 + 'ms',
          size: resource.transferSize ? `${Math.round(resource.transferSize / 1024)} KB` : 'Unknown',
          type: resource.initiatorType
        });
      }
    });
  });

  observer.observe({ entryTypes: ['resource'] });
  return observer;
};
