const insuranceAgencySchema = {
  "@context": "https://schema.org",
  "@type": "InsuranceAgency",
  "name": "1swis",
  "description": "Comparateur d'Assurance Santé en ligne",
  "url": "https://1swis.com",
  "logo": "https://1swis.com/1swis.png",
  "sameAs": [
    "https://www.facebook.com/1swis",
    "https://www.linkedin.com/company/1swis",
    "https://twitter.com/1swis"
  ],
  "areaServed": {
    "@type": "Country",
    "name": "France"
  },
  "potentialAction": {
    "@type": "ComparativeAction",
    "target": "https://1swis.com/comparer"
  },
};

export default insuranceAgencySchema;
