import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IStep01 {
  // Birth and Nationality Information (New fields)
  nationality: string;
  birthPlace: string;
  birthCountry: string;
  securiteSociale: string;
  
  // New Address Field
  streetAddress: string;

  // Insurance Information (from Step02)
  hasMutuelle: boolean;
  currentMutuelle?: {
    name: string;
    endDate: string;
    provider?: string;
  };

  // Bank Account Information (from Step02)
  bankDetails: {
    bankName: string;
    iban: string;
    bic: string;
  };

  // Payment Preferences (from Step02)
  paymentPreferences: {
    frequency: "Mensuel" | "Annuel";
    collectionDate: "Le10" | "Le15";
    automaticDebit: boolean;
    directDebitTerms: boolean;
  };

  // Consent Information (from Step03)
  consent: {
    termsAccepted: boolean;
    autoDebitAccepted: boolean;
    communicationAccepted: boolean;
  };
}

const initialState: IStep01 = {
  // Birth and Nationality Information
  nationality: "",
  birthPlace: "",
  birthCountry: "",
  securiteSociale: "",
  
  // Address
  streetAddress: "",

  // Insurance Information
  hasMutuelle: false,
  currentMutuelle: undefined,

  // Bank Account Information
  bankDetails: {
    bankName: "",
    iban: "",
    bic: "",
  },

  // Payment Preferences
  paymentPreferences: {
    frequency: "Mensuel",
    collectionDate: "Le10",
    automaticDebit: false,
    directDebitTerms: false,
  },

  // Consent Information
  consent: {
    termsAccepted: false,
    autoDebitAccepted: false,
    communicationAccepted: false,
  },
};

const step01Slice = createSlice({
  name: "step01",
  initialState,
  reducers: {
    // Birth and Nationality Information
    setBirthInfo: (state, action: PayloadAction<{
      nationality?: string;
      birthPlace?: string;
      birthCountry?: string;
      securiteSociale?: string;
      signatureId?: string;
    }>) => {
      Object.assign(state, action.payload);
    },
    
    // Address Information
    setAddressInfo: (state, action: PayloadAction<{
      streetAddress?: string;
    }>) => {
      Object.assign(state, action.payload);
    },

    // Insurance Information
    setInsuranceInfo: (state, action: PayloadAction<{
      hasMutuelle: boolean;
      currentMutuelle?: {
        name: string;
        endDate: string;
        provider?: string;
      };
    }>) => {
      state.hasMutuelle = action.payload.hasMutuelle;
      state.currentMutuelle = action.payload.currentMutuelle;
    },

    // Bank Account Information
    setBankDetails: (state, action: PayloadAction<{
      bankName?: string;
      iban?: string;
      bic?: string;
    }>) => {
      state.bankDetails = {
        ...state.bankDetails,
        ...action.payload
      };
    },

    // Payment Preferences
    setPaymentPreferences: (state, action: PayloadAction<{
      frequency?: "Mensuel" | "Annuel";
      collectionDate?: "Le10" | "Le15";
      automaticDebit?: boolean;
      directDebitTerms?: boolean;
    }>) => {
      state.paymentPreferences = {
        ...state.paymentPreferences,
        ...action.payload
      };
    },

    // Consent Information
    setConsent: (state, action: PayloadAction<{
      termsAccepted?: boolean;
      autoDebitAccepted?: boolean;
      communicationAccepted?: boolean;
    }>) => {
      state.consent = {
        ...state.consent,
        ...action.payload
      };
    },
    
    // Reset
    resetStep01: (state) => {
      return initialState;
    },
  },
});

export const step01Actions = step01Slice.actions;
export default step01Slice;
