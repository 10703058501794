import Sidebar from "./Sidebar";
import Header from "./header";

import { useEffect, useState } from "react";

const Layout = () => {
  // sidebar Open Close  

  const [isOpen, setIsOpen] = useState(false)
  console.log(isOpen)
  if (isOpen) {
    document.body.className = 'toggle-sidebar';
  } else {
    document.body.classList.remove('toggle-sidebar');
  }

  // End sidebar Open Close  
  const [showSidebar, setShowSidebar] = useState(true);

  function handleSize() {
    if (window.innerWidth > 768) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }

  useEffect(() => {
    import("../../Admin/assets/js/main.js");
    document.documentElement.style.setProperty("--white", "#f6f9ff");

    handleSize();
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);
  return (
    <div>
      <Header isOpen={isOpen}  setIsOpen={setIsOpen} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Sidebar isOpen={isOpen}  setIsOpen={setIsOpen} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
    </div>
  );
};

export default Layout;
