import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface UserSession {
    auth: boolean,
    role: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    isEmailVerified: boolean;
    id: string;
    image: string
}


const UserAuthCredential = createSlice({
    name: "UserAuthCredential",
    initialState: {
        user: {
            auth: false,
            role: '',
            fullName: '',
            email: '',
            phoneNumber: '',
            isEmailVerified: false,
            id: '',
            image: ""
        },
    },
    reducers: {
        setUserAuth: (state, action: PayloadAction<UserSession>) => {
            state.user = action.payload;
        },
    },
});

export const UserAuthActions = UserAuthCredential.actions;
export default UserAuthCredential;
