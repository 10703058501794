import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type WarrantyLevel = "Minimum" | "Moyen" | "Fort" | "Maximum";

export interface IWarranties {
  medicalCare: { level: WarrantyLevel };
  dental: { level: WarrantyLevel };
  visual: { level: WarrantyLevel };
  hearing: { level: WarrantyLevel };
  hospitalization: { level: WarrantyLevel };
}

const initialState: IWarranties = {
  medicalCare: { level: "Minimum" },
  dental: { level: "Minimum" },
  visual: { level: "Minimum" },
  hearing: { level: "Minimum" },
  hospitalization: { level: "Minimum" },
};

const warrantiesSlice = createSlice({
  name: "warranties",
  initialState,
  reducers: {
    setLevel: (
      state,
      action: PayloadAction<{
        warranty: keyof IWarranties;
        level: WarrantyLevel;
      }>
    ) => {
      state[action.payload.warranty].level = action.payload.level;
    },
  },
});

export const warrantiesActions = warrantiesSlice.actions;
export default warrantiesSlice;
