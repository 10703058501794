import WebRoutes from "./routes/WebRoutes";
import "./App.css";
import { useState, useEffect } from "react";
import NoInternetPage from "./components/UI/noInternet";
import SchemaMarkup from "./components/SEO/SchemaMarkup";

function App() {
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, [navigator.onLine]);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return (
    <>
      {/* <SEO /> */}
      <SchemaMarkup />
      {isOnline ? <WebRoutes /> : <NoInternetPage />}
    </>
  );
}

export default App;
