import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface INavigation {
  activeAppPage: 1 | 2 | 3 | 4;
  activeFormPage: 1 | 2 | 3;
}

const initialState: INavigation = {
  activeAppPage: 1,
  activeFormPage: 1,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setActiveFormPage: (
      state,
      action: PayloadAction<INavigation["activeFormPage"]>
    ) => {
      window.scrollTo(0, 0);
      state.activeFormPage = action.payload;
    },
    setActiveAppPage: (
      state,
      action: PayloadAction<INavigation["activeAppPage"]>
    ) => {
      window.scrollTo(0, 0);
      state.activeAppPage = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;
export default navigationSlice;
