import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Offer } from "../../components/hooks/useAPI";

interface OffersState {
  offers: Offer[] | null;
}

const initialState: OffersState = {
  offers: null,
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOffers: (state, action: PayloadAction<Offer[]>) => {
      state.offers = action.payload;
    },
  },
});

export const { setOffers } = offersSlice.actions;
export default offersSlice;
