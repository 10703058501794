import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IMembers {
  status:
    | "Un adulte"
    | "Un couple"
    | "Un adulte + enfant(s)"
    | "Un couple + enfant(s)"
    | "";
  gender: "Homme" | "Femme" | "";
  birthDate: string;
  hasPartner: boolean;
  childs: Number;
  partnerGender?: "Homme" | "Femme" | "";
  partnerBirthDate?: string;
  activity: string;
  socialRegime: string;
  children?: {
    child1?: {
      birthDate: string;
      activity?: string;
    };
    child2?: {
      birthDate: string;
      activity?: string;
    };
    child3?: {
      birthDate: string;
      activity?: string;
    };
    child4?: {
      birthDate: string;
      activity?: string;
    };
    child5?: {
      birthDate: string;
      activity?: string;
    };
    child6?: {
      birthDate: string;
      activity?: string;
    };
    child7?: {
      birthDate: string;
      activity?: string;
    };
    child8?: {
      birthDate: string;
      activity?: string;
    };
    child9?: {
      birthDate: string;
      activity?: string;
    };
  };
}

export type ChildNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

const initialState: IMembers = {
  status: "",
  gender: "",
  birthDate: "",
  hasPartner: false,
  activity: "",
  socialRegime: "",
  childs: 0,
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<IMembers["status"]>) => {
      state.status = action.payload;
      if (
        action.payload === "Un couple" ||
        action.payload === "Un couple + enfant(s)"
      ) {
        state.hasPartner = true;
      } else {
        state.hasPartner = false;
      }
    },
    setChildsNumber: (state, action: PayloadAction<IMembers["childs"]>) => {
      state.childs = action.payload;
    },
    setGender: (state, action: PayloadAction<IMembers["gender"]>) => {
      state.gender = action.payload;
    },
    setBirthDate: (state, action: PayloadAction<string>) => {
      state.birthDate = action.payload;
    },
    setActivity: (state, action: PayloadAction<IMembers["activity"]>) => {
      state.activity = action.payload;
    },
    setSocialRegime: (
      state,
      action: PayloadAction<IMembers["socialRegime"]>
    ) => {
      state.socialRegime = action.payload;
    },
    setPartnerGender: (
      state,
      action: PayloadAction<IMembers["partnerGender"]>
    ) => {
      state.partnerGender = action.payload;
    },
    setPartnerBirthDate: (state, action: PayloadAction<string>) => {
      state.partnerBirthDate = action.payload;
    },
    setChilds: (state, action: PayloadAction<ChildNumber>) => {
      state.children = {};

      for (let i = 1; i <= action.payload; i++) {
        state.children[`child${i as ChildNumber}`] = { birthDate: "" };
      }
    },
    setChildBirthDate: (
      state,
      action: PayloadAction<{
        childNumber: ChildNumber;
        birthDate: string;
      }>
    ) => {
      state.children![`child${action.payload.childNumber}`] = {
        birthDate: action.payload.birthDate,
      };
    },
  },
});

export const membersActions = membersSlice.actions;
export default membersSlice;
