import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Offer as IOffer } from "../../components/hooks/useAPI";

interface Offer {
  provider: IOffer["provider"];
  monthlyPrice: number;
  levels: (1 | 2 | 3 | 4)[];
  name?: string;
  code?: string;
  description?: string;
  label?: string;
}
const selectedOffer = createSlice({
  name: "selectedOffer",
  initialState: {
    offer: {},
  },
  reducers: {
    setOffer: (state, action: PayloadAction<Offer>) => {
      window.scrollTo(0, 0);
      state.offer = action.payload;
    },
  },
});

export const offerActions = selectedOffer.actions;
export default selectedOffer;
