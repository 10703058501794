export const Keys = {
  AuthData: "authData",
  SessionId: "sessionId",
};

export const ActionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
};

// API's end point

export const api = {
  login: "/auth/v1/user/login",
  signup: "/auth/v1/user/signup",
  myOffer: "/api/v1/confirm-offer/get-user-offers",
  refreshToken: "/auth/v1/user/refresh-tokens",
  confirmOffer: "/api/v1/confirm-offer",
  resetPassword: "/auth/v1/user/reset-password",
  verifyOtp: "/api/v1/admin/verify-otp",
  contact: "/api/v1/contact",
  contrat: "/api/v1/contrat",
  contratById: "/api/v1/contrat/byId",
  subscriberSearch: "/api/v1/contrat/subscriber-search",
  contactById: "/api/v1/contact/byId",
  contatUploadFile: "/api/v1/contact/upload-file",
  twoFA: "/api/v1/admin/enable-2fa",
  verifyTowFA: "/api/v1/admin/verify-2fa",
  contactus: "/api/v1/contactus"
};

/**
 * Sets a key/value pair in session storage.
 * @param key - key of the object to set.
 * @param value - value of the object to set
 */

export const setSession = (key, value) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (error) {
    throw new Error(`set: '${key}'`);
  }
};
/**
 * Returns an object from storage or an empty object if no data is found.
 * @param key - key of the object to retrieve.
 * @returns object from storage or an empty object if no data is found.
 */
export const getSession = (key) => {
  try {
    const token = sessionStorage.getItem(key);
    return token;
  } catch (error) {
    throw new Error(`getObject: '${key}'`);
  }
};

/**
 * Removes a key/value pair from storage.
 * @param key - Key of the object to remove.
 */
export const removeSession = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    throw new Error(`remove: '${key}'`);
  }
};

/**
 * Sets a key/value pair in storage.
 * @param key - key of the object to set.
 * @param value - value of the object to set
 */

export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    throw new Error(`set: '${key}'`);
  }
};

export const getLocalStorage = (key) => {
  try {
    const stringifiedData = localStorage.getItem(key);
    return stringifiedData ? JSON.parse(stringifiedData) : {};
  } catch (error) {
    throw new Error(`getObject: '${key}'`);
  }
};

/**
 * Removes a key/value pair from storage.
 * @param key - Key of the object to remove.
 */
export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    throw new Error(`remove: '${key}'`);
  }
};
