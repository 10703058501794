export default {
  common: {
    firstName: "Prénom",
    lastName: "nom de famille",
    name: "nom",
    email: "E-mail",
    fullName: "Entrez le nom",
    phone: "Téléphone",
    enterEmail: "Entrez l'e-mail",
    query: "Requête",
    confirm: "Confirmer",
    cancel: "Annuler",
    back: "Dos",
    fullDetails: "Remplir les détails",
    submit: "Soumettre",
    success: "Succès",
    error: "Erreur",
    members: "Adhérents",
    continue: "Continuer",
    dashboard: "Tableau de bord",
    manageOffers: "Portefeuille",
    manageUsersQueries: "Gérer les requêtes des utilisateurs",
    home: "Maison",
    subscribers: "Abonnées",
    totalSubscribers: "Nombre total d'abonnés", // Total subscribers
    insuredUsers: "Utilisateurs assurés", // Insured Users
    totalInsuredUsers: "Total des utilisateurs assurés", // Total insured users
    edit: "Modifier",
    verify: "Vérifié",
    notVerify: "Non vérifié",
    saveChanges: "Enregistrer les modifications", // Save Changes
    signOut: "se déconnecter", // sign out
    search: "Recherche",
  },
  Chatbot: {
    Chatbot: "Besoin d'aide ?",
    otherMessage: "Si vous avez d'autres questions...",
    askQuestion: "Poser une question",
    faqQuestions: [
      {
        parentId: 0,
        id: 1,
        faqTitle: "Fonctionnement du Comparateur",
        // faqDescription: "Requêtes générales", // General queries
      },
      {
        parentId: 0,
        id: 2,
        faqTitle: "Souscription et Résiliation", // Installation and Integration
        // faqDescription:
        //   "Requêtes sur l'intégration de Kommunicate dans toutes vos plateformes, telles que les sites web, CMS, applications mobiles natives et hybrides.",
        // Queries about integrating Kommunicate in all your platforms such as websites, CMS, native and hybrid mobile apps.
      },
      {
        parentId: 0,
        id: 3,
        faqTitle: "Paiements et Tarifs", // Billing and Payments
        // faqDescription:
        //   "Trouvez toutes vos réponses sur les prix, la facturation et autres questions liées aux paiements.", // Find all your answers to pricing, billing, and other payment-related queries.
      },

      {
        parentId: 0,
        id: 4,
        faqTitle: "Assistance Technique",
        // faqDescription:
        //   "Trouvez toutes vos réponses sur les prix, la facturation et autres questions liées aux paiements.", // Find all your answers to pricing, billing, and other payment-related queries.
      },
      {
        parentId: 0,
        id: 5,
        faqTitle: "Informations Personnalisées",
        // faqDescription:
        //   "Trouvez toutes vos réponses sur les prix, la facturation et autres questions liées aux paiements.", // Find all your answers to pricing, billing, and other payment-related queries.
      },
      {
        parentId: 1,
        id: 6,
        faqTitle: "Comment fonctionne votre comparateur d'assurance santé?", // No agent
        faqDescription:
          "Notre comparateur analyse les offres de plusieurs assureurs et vous propose les meilleures solutions en fonction de vos besoins spécifiques. Il suffit de répondre à quelques questions, et vous recevrez des devis personnalisés en quelques minutes.",
        // No agent is currently available, please submit your query.
      },
      {
        parentId: 1,
        id: 7,
        faqTitle: "Est-ce que l'utilisation du comparateur est gratuite ?", // No agent
        faqDescription:
          "Oui, notre comparateur est entièrement gratuit et sans engagement. Vous pouvez obtenir des devis sans frais et décider en toute tranquillité de souscrire ou non à une offre.",
        // No agent is currently available, please submit your query.
      },
      {
        parentId: 1,
        id: 8,
        faqTitle:
          "Combien de temps cela prend-il pour obtenir une comparaison ?", // No agent
        faqDescription:
          "Réponse :* En général, il faut moins de 2 minutes pour remplir le formulaire et obtenir une comparaison des meilleures offres.",
        // No agent is currently available, please submit your query.
      },
      {
        parentId: 2,
        id: 9,
        faqTitle:
          "Comment souscrire à une assurance santé après avoir utilisé le comparateur ?", // Installation and Integration
        faqDescription:
          " Une fois que vous avez choisi l’offre qui vous convient, vous pouvez souscrire directement en ligne en suivant les instructions de l'assureur sélectionné. Si vous avez des questions supplémentaires, n'hésitez pas à nous contacter à `support@1swis.com` ou par téléphone au `01 84 20 00 60`.",
      },
      {
        parentId: 2,
        id: 10,
        faqTitle: "Puis-je changer de mutuelle à tout moment ?", // Installation and Integration
        faqDescription:
          "Oui, vous pouvez changer de mutuelle à tout moment. Cependant, il est important de vérifier les conditions de résiliation de votre contrat actuel, notamment si vous êtes encore dans une période d'engagement.",
      },
      {
        parentId: 2,
        id: 11,
        faqTitle:
          "Comment résilier ma mutuelle actuelle pour souscrire à une nouvelle ?",
        faqDescription:
          "Vous pouvez résilier votre mutuelle actuelle en envoyant une demande à votre assureur, souvent par lettre recommandée. Une fois la résiliation effectuée, vous pourrez souscrire à une nouvelle offre via notre comparateur.",
      },
      {
        parentId: 3,
        id: 12,
        faqTitle:
          "Est-ce que je paye pour souscrire à une assurance santé via votre site ?",
        faqDescription:
          "Non, vous ne payez pas de frais pour utiliser notre comparateur. Les paiements liés à la souscription d'une assurance se font directement avec l'assureur choisi.",
      },
      {
        parentId: 3,
        id: 13,
        faqTitle: "Comment sont calculés les prix des assurances santé ?",
        faqDescription:
          "Les prix sont calculés en fonction de plusieurs critères, comme votre âge, votre lieu de résidence, la composition de votre foyer, ainsi que vos besoins spécifiques en matière de santé.",
      },
      {
        parentId: 3,
        id: 14,
        faqTitle:
          "Comment se passent les paiements une fois que j'ai choisi une assurance ?",
        faqDescription:
          " Une fois que vous avez choisi une assurance santé, vous effectuerez les paiements directement auprès de l'assureur. Les modalités de paiement seront expliquées par l'assureur lors de la souscription.",
      },
      {
        parentId: 4,
        id: 15,
        faqTitle: "Je rencontre un problème technique, que dois-je faire ?",
        faqDescription:
          "Si vous rencontrez un problème technique avec notre comparateur, vous pouvez nous contacter directement via e-mail à `support@1swis.com` ou par téléphone au `01 84 20 00 60`. Nous vous répondrons dans les plus brefs délais.",
      },
      {
        parentId: 4,
        id: 16,
        faqTitle: "Mon devis n'a pas été généré correctement, que faire ?",
        faqDescription:
          "Si vous constatez un problème avec votre devis, veuillez essayer de rafraîchir la page ou de remplir à nouveau le formulaire. Si le problème persiste, contactez notre service d'assistance à `support@1swis.com`.",
      },
      {
        parentId: 5,
        id: 17,
        faqTitle:
          "Puis-je modifier mes critères après avoir obtenu des résultats ?",
        faqDescription:
          "Oui, vous pouvez ajuster vos critères à tout moment. Notre comparateur est flexible : si vous souhaitez changer vos préférences ou explorer d'autres options, il suffit de mettre à jour vos réponses pour voir des résultats personnalisés.",
      },
      {
        parentId: 5,
        id: 18,
        faqTitle: "Comment être sûr de choisir la bonne mutuelle ?",
        faqDescription:
          "Nous vous recommandons de comparer les garanties proposées par chaque mutuelle et d’évaluer leurs niveaux de remboursement en fonction de vos besoins personnels. Vous pouvez également consulter les avis des clients pour vous aider à faire votre choix.",
      },
      {
        parentId: 9,
        id: 10,
        faqTitle: "Installation et Intégration",
        faqDescription:
          "Il est un fait établi de longue date qu'un lecteur sera distrait par le contenu lisible d'une page lorsqu'il regarde sa mise en page. L'intérêt d'utiliser Lorem Ipsum est qu'il a une distribution plus ou moins normale des lettres, contrairement à l'utilisation de 'Contenu ici, contenu ici', ce qui le fait ressembler à de l'anglais lisible.",
      },
    ],
  },
  questions: {
    address: {
      title: "Votre ville ou code postal",
    },
  },
  offer: {
    offer: "Offres", // Offers
    confirmOffierModalTitle: "Confirmer l'offre", // Confirm Offer
    confirmOfferModalBody: "Êtes-vous sûr de vouloir continuer", // "Are sure you want to proceed"
    successALert: "Confirmé votre offre avec succès", // Confirmed your offer successfully
    notSendEmail:
      "J'ai confirmé votre offre mais je n'ai pas pu envoyer de mail de confirmation.", // Confirmed your offer but unable to send confirmation mail.
    notSelectSlot: "Veuillez sélectionner une date/créneau horaire approprié", // Please select any suitable date/time slot
    errorMessage:
      "Une erreur s'est produite lors de la confirmation de votre offre. Veuillez réessayer.", // An error occurred while confirming your offer. Please try again.
    recievedOffers: "Offres reçues", // Recieved Offers
    totalRecievedOffers: "Total des offres reçues", // Total recieved offers
    submittedOffers: "Offres soumises", // Submitted Offers
    exportCSV: "Exporter au format CSV", // Export CSV
  },
  admin: {
    admin: "Administratrice",
    subAdmin: "Sous-administrateur",
    login: "se connecter",
    password: "Mot de passe", // Password
    forgotPassword: "Mot de passe oublié", // Forgot Password?
    resetPassword: "Réinitialiser le mot de passe", // Reset Password
    changePassword: "Changer le mot de passe", // Change Password
    enterUserName: "Entrez votre email ou votre nom d'utilisateur", // Enter your email or username
    passwordPlaceholder: "Entrez votre mot de passe", // Enter your password
    currentPassword: "Mot de passe actuel", // Current Password
    currentPasswordPlaceholder: "Entrez votre mot de passe actuel", // Enter your current password
    newPassword: "New Password", // New Password
    newPasswordPlaceholder: "Entrez votre nouveau mot de passe", // Enter your new password
    confirmPassword: "Ressaisissez le nouveau mot de passe", // Re-enter New Password
    confirmPasswordPlaceholder: "Confirmez votre mot de passe", // Cofirm your password
    backToLogin: "Retour à la connexion", // Back to login
    offerHeader: {
      email: "Email",
      phoneNumber: "Numéro de téléphone",
      city: "Ville",
      provider: "Fournisseur",
      monthlyPrice: "Prix mensuel",
      yearlyPrice: "Prix annuel",
      dateSlot: "Plage de dates",
      timeSlot: "Plage horaire",
      createdDate: "Date de création",
    },
    addNew: "Ajouter un nouveau",
    selectRole: "Sélectionnez un rôle",
  },
  query: {
    usersName: "Nom de l'utilisateur", // Users Name
    usersEmail: "Email de l'utilisateur", // Users Email
    Status: "Statut", // Status
    Query: "Requête", // Query
    createdDate: "Date de création", // Create Date
    Action: "Action", // Action`
    feedbackForm: "Formulaire de commentaires", // Feedback Form
    usersQueries: "Requêtes des utilisateurs", // Users Queries
    queriesFeedback: "Retour sur les requêtes.", // Queries Feedback
    feedbackPlaceholder: "Entrez vos commentaires.", // Enter your feedback
  },
  manageAdmin: {
    manage: "Gérer l'administrateur", // Manage Admin
    role: "Rôle",
    addNewAdmin: "Ajouter un nouvel administrateur", // Add new admin
    addAdmin: "Ajouter un administrateur", // Add admin
    dataNotExist: "Les données n'existent pas!", // Data not exist
  },
  profile: {
    editProfile: "Modifier le profil", // Edit Profile
    profileImage: "Image de profil", // Profile image
    myProfile: "Mon profil", // My Profile
    noNotification: "Aucune notification", // No Notification
  },
  paginationRow: [
    { value: 10, label: "afficher 10 lignes" }, // show 10 rows
    { value: 20, label: "afficher 20 lignes" }, // show 20 rows
    { value: 25, label: "afficher 25 lignes" }, // show 25 rows
    { value: 50, label: "afficher 50 lignes" }, // show 50 rows
  ],
};

// Backend strings

// chatbotSuccess= "Your query has been successfully submitted. Our team will get back to you shortly",
// chatbotError= "Impossible de soumettre vos requêtes. Essayez après un certain temps"
// addError= "All fields are mandatory!","Tous les champs sont obligatoires !"

// // getChatbotMessage
// Toutes les requêtes de l'utilisateur = User's all queries
// Quelque chose s'est mal passé! = Something went wrong!
// Quelque chose s'est mal passé ! Réessayez ou contactez-nous. = Something went wrong! Try again or contact us.
// Échec de la récupération des notifications. =  Failed to retrieve notifications.
// Les données de notification n'existent pas ! = Notification data does not exist!
// L'accès à cet itinéraire est interdit ! = Access to this route is forbidden!
// Les données des requêtes n'existent pas ! = Queries data not exist!
// Vous êtes déjà abonné à cette offre. Veuillez sélectionner une autre offre. = You have already subscribed to this offer. Please select another offer.
