const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Comment comparer les assurances santé?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Utilisez notre comparateur en ligne gratuit pour comparer instantanément les offres d'assurance santé. Remplissez le formulaire avec vos besoins et obtenez des devis personnalisés."
    }
  }, {
    "@type": "Question",
    "name": "Comment économiser sur son assurance santé?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Comparez les offres sur 1swis, analysez les garanties dont vous avez réellement besoin, et économisez jusqu'à 500€ sur votre mutuelle."
    }
  }]
};

export default faqSchema;
