import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import navigationSlice from "./appStateSlices/navigationSlice";
import credentialsSlice from "./userDataSlices/credentialsSlice";
import membersSlice from "./userDataSlices/membersSlice";
import warrantiesSlice from "./userDataSlices/warrantiesSlice";
import step01Slice from "./userDataSlices/step01Slice";
import offersSlice from "./slices/offersSlice";

import selectedOffer from "./appStateSlices/selectedOffer";
import AuthCredential from "./appStateSlices/AuthCredentialsSlice";
import UserAuthCredential from "./appStateSlices/userAuthSlice";
import otherActivitysSlice from "./appStateSlices/othersActivity";

// Combine reducers
const rootReducer = combineReducers({
  userData: combineReducers({
    members: membersSlice.reducer,
    warranties: warrantiesSlice.reducer,
    credentials: credentialsSlice.reducer,
    step01: step01Slice.reducer,
  }),
  appState: combineReducers({
    navigation: navigationSlice.reducer,
    offers: offersSlice.reducer,
  }),
  confirmOffer: combineReducers({
    selectedOffer: selectedOffer.reducer,
  }),
  authCredential: AuthCredential.reducer,
  user: UserAuthCredential.reducer,
  otherActivity: otherActivitysSlice.reducer,
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authCredential", "user"],
};

// Apply persistReducer to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer, // Use persisted rootReducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
