import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ICredentials {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  city: string;
  postalCode: string;
  insureDate: string;
}

const initialState: ICredentials = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  city: "",
  postalCode: "",
  insureDate: "",
};

const credentialsSlice = createSlice({
  name: "credentials",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setAddress: (state, action: PayloadAction<string[]>) => {
      state.postalCode = action.payload[0];
      state.city = action.payload[1];
    },
    setInsureDate: (state, action: PayloadAction<string>) => {
      state.insureDate = action.payload;
    },
  },
});

export const credentialsActions = credentialsSlice.actions;
export default credentialsSlice;
