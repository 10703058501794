import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface Other {
  isCollapsed: boolean;
  activeMenu: string | null; // Adjust the type based on your requirement
}

const initialState: Other = {
  isCollapsed: false,
  activeMenu: null,
};

const otherActivitysSlice = createSlice({
  name: "otherActivitys",
  initialState,
  reducers: {
    setOtherActivity: (state, action: PayloadAction<Other>) => {
    //   window.scrollTo(0, 0);
      state.isCollapsed = action.payload.isCollapsed;
      state.activeMenu = action.payload.activeMenu;
    },
  },
});

export const otherActivitysActions = otherActivitysSlice.actions;
export default otherActivitysSlice;
